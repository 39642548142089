<template>
  <div class="progress">
    <div
      class="progress-bar"
      :style="`width: ${percentageWidth}%;`"
    >
    </div>
    <div class="progress__label">
      <span v-if="showPercentage">{{ percentageWidth }} %</span>
      <span v-else>{{ value | formatNumber }}</span>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    value: {
      type: Number
    },
    max: {
      type: Number
    },
    showPercentage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    percentageWidth () {
      if (!this.value || !this.max) {
        return 0
      }
      return Math.ceil((this.value / this.max) * 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.progress {
  position: relative;
  background-color: #ECF2FF;
  &__label {
    position: absolute;
    right: rem(6px);
    top: 25%;
    @include font(400 12px "Roboto");
    color: #262B33;
  }
}
.progress-bar {
  background: #6599FE;
  border-radius: rem(6px) rem(0px) rem(0px) rem(6px);
  height: rem(32px);
}

</style>
