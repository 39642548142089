<template>
  <section class="native-performance-prediction">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <LineChart class="col-lg-10" :chart-data="lineChartData" :height="250"/>
          <NativePerfPredictionsLegend class="col-lg-2 mt-4 mt-lg-0"/>
        </div>
      </div>
    </div>

    <div class="card card-no-border">
      <NativeCampaignManagementFilter class="card-body"/>
    </div>

    <Preloader v-if="callingAPI" on-top/>

    <Autoloader v-if="autoRefresh" :callback="fetchNativeCampaign" :height="2" :interval="60"/>

    <div class="card">
      <NativeCampaignManagementTable class="card-body" hide-buttons table-classes="p-0"/>
    </div>

    <div v-if="campaigns.length" class="card">
      <NativePerfPredictionTable class="card-body"/>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import NativeCampaignManagementFilter from '@/components/filter/FilterNativeCampaignManagement'
import NativeCampaignManagementTable from '@/components/table/NativeCampaignManagementTable'
import Autoloader from '@/components/Autoloader'
import LineChart from '@/components/chart/LineChart'
import NativePerfPredictionsLegend from '@/components/don/NativePerfPredictionsLegend.vue'
import NativePerfPredictionTable from '@/components/don/NativePerfPredictionsTable.vue'

export default {
  name: 'NativePerfPredictions',
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters({
      forecastOverall: 'nativePerfPredictions/forecastOverall',
      campaigns: 'nativeCampaignManagement/list',
      autoRefresh: 'nativeCampaignManagement/autoRefresh'
    }),
    lineChartData () {
      return {
        labels: this.forecastOverall.dailyTotal.map(item => item.date),
        datasets: [
          {
            label: this.$t('donBox.prediction.clicks'),
            data: this.forecastOverall.dailyTotal.map(item => item.clicks),
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            fill: true
          },
          {
            label: this.$t('donBox.prediction.clicksForecast'),
            data: this.forecastOverall.dailyTotal.map(item => item.clicksForecast),
            borderColor: 'rgba(54, 162, 235, 1)',
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            fill: true
          },
          {
            label: this.$t('donBox.prediction.clicksReserved'),
            data: this.forecastOverall.dailyTotal.map(item => item.clicksReserved),
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            fill: true
          }
        ]
      }
    }
  },
  components: {
    NativePerfPredictionTable,
    NativePerfPredictionsLegend,
    LineChart,
    NativeCampaignManagementTable,
    NativeCampaignManagementFilter,
    Preloader,
    Autoloader
  },
  methods: {
    fetchForecastOverall () {
      this.$store.dispatch('nativePerfPredictions/fetchForecastOverall')
    },
    fetchNativeCampaign () {
      this.$store.dispatch('nativeCampaignManagement/fetch')
    }
  },
  created () {
    this.fetchForecastOverall()
  }
}
</script>

<style lang="scss" scoped>
</style>
