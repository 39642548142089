<template>
  <div>
    <div style="overflow-x: auto;">
      <AbstractDataTable
        ref="nativeCampaignTable"
        :headers="tableHeaders"
        :items="nativeCampaigns"
        :total-count="totalCount"
        :current-page="currentPage"
        @current-page-changed="setCurrentPage"
        :on-page-change="setPageAndGetRecords"
        columns-gap="12px"
        :show-edit-button="showEditButton"
        :show-duplicate-button="showDuplicateButton"
        :show-delete-button="() => false"
        :show-pagination="false"
        :show-actions="false"
        row-tooltip-prop="title"
        action-id-param="campaignId"
        table-css-class="native-campaign-table"
        :class="tableClasses"
        tooltip-placement="bottom"
        >
        <template #tableBody="{ item }">
          <AbstractColumn :header="tableHeaders[0].name">
            <span :class="`label-status label-status--${item.status.toLowerCase()}`">
              {{ item.status.toUpperCase() }}
            </span>
            <div class="actions">
              <ButtonTableAction v-if="showInfoButton" icon="info" @click.stop="$refs.nativeCampaignTable.infoButtonClick(item)" />
              <ButtonTableAction v-if="showEditButton(item)" icon="edit" @click.stop="$refs.nativeCampaignTable.editButtonClick(item)" />
              <ButtonTableAction v-if="showDuplicateButton(item)" icon="duplicate" @click.stop="goToDuplicate(item)" />
            </div>
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[1].name">
            {{ item.agency }}
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[2].name">
            {{ item.client }}
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[3].name">
            {{ item.title }}
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[4].name">
            {{ item.publishedFrom | prettyDate }} - {{ item.publishedTo | prettyDate }}
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[5].name" class="centered-column">
            <i v-if="!item.isFree" class="fa fa-check"></i>
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[6].name" class="number-column">
            {{ item.numberOfAlternatives | formatNumber }}
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[7].name" class="number-column">
            {{ item.activeDays | formatNumber }}
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[8].name" class="number-column">
            {{ (item.inviewImpressions ? item.inviewImpressions : 0) | formatNumber }}
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[9].name" class="number-column">
            <div class="m-b-5">
              {{ (item.clicks ? item.clicks : 0) | formatNumber }}
              {{ ` / ` }}
              {{ (item.clicksLimit ? item.clicksLimit : 0) | formatNumber }}
            </div>
            <ProgressBar
              :value="item.fulfillment * 100"
              :max="100"
              show-percentage
            />
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[10].name" class="number-column">
            {{ item.ctr | formatPercentage }}
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[11].name" class="number-column">
            {{ item.clickPrice | formatCurrency }}
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[12].name" class="number-column">
            {{ item.sumPrice | formatCurrency }}
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[13].name" class="number-column">
            {{ item.budget | formatCurrency }}
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[14].name">
            <ProgressBar
              :value="item.bonusFulfillment * 100"
              :max="100"
              show-percentage
              />
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[15].name" class="number-column">
            <i v-if="item.rrk" class="fa fa-check"></i>
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[16].name">
            {{ item.note }}
          </AbstractColumn>
          <AbstractColumn :header="tableHeaders[17].name">
            {{ item.salesman }}
          </AbstractColumn>
        </template>
      </AbstractDataTable>
    </div>
    <div
      class="campaign-table-pagination"
      v-if="nativeCampaigns.length"
    >
      <div class="campaign-table-pagination__left-side">
        {{ `${$t('page_record_number')}:` }}
        <Select
          class="pagination-setting__limit"
          v-model="limit"
          :options="limitOptions"
          id="limit"
          no-label
          no-empty-value
          disable-form-group
        />
        <TotalCount :total-count="totalCount" />
      </div>
      <Pagination
        :value="currentPage"
        @input="setCurrentPage"
        :page-count="pageCount"
        :click-handler="setPageAndGetRecords"
      />
    </div>
  </div>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import AbstractColumn from '@/components/table/columns/AbstractColumn'
import ProgressBar from '@/components/video/videoStats/ProgressBar'
import Pagination from '@/components/Pagination'
import TotalCount from '@/components/article/ArticleTotalCount'
import ButtonTableAction from '@/components/buttons/ButtonTableAction'
import Select from '@/components/form/select/Select'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'NativeCampaignTable',
  components: {
    ButtonTableAction,
    ProgressBar,
    AbstractColumn,
    AbstractDataTable,
    Pagination,
    TotalCount,
    Select
  },
  data () {
    return {
      alignRight: 'text-align: right;',
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.NATIVE_CAMPAIGN_MANAGEMENT_PERMISSIONS
    }
  },
  props: {
    hideButtons: {
      type: Boolean,
      default: false
    },
    tableClasses: {
      type: String,
      default: ''
    }
  },
  computed: {
    tableHeaders () {
      return [
        { name: this.$t('donBox.nativeCampaign.status') },
        { name: this.$t('donBox.nativeCampaign.agency') },
        { name: this.$t('donBox.nativeCampaign.client') },
        { name: this.$t('donBox.nativeCampaign.title') },
        { name: this.$t('donBox.nativeCampaign.interval') },
        { name: this.$t('donBox.nativeCampaign.paidCampaign'), style: 'text-align: center;' },
        { name: this.$t('donBox.nativeCampaign.alternatives'), style: this.alignRight },
        {
          name: this.$t('donBox.nativeCampaign.daysColumn'),
          tooltip: this.$t('donBox.nativeCampaign.daysTooltip'),
          style: this.alignRight
        },
        {
          name: this.$t('donBox.nativeCampaign.visitsColumn'),
          tooltip: this.$t('donBox.nativeCampaign.visitsTooltip'),
          style: this.alignRight
        },
        {
          name: this.$t('donBox.nativeCampaign.clicks'),
          tooltip: this.$t('donBox.nativeCampaign.clicksTooltip'),
          style: this.alignRight
        },
        {
          name: this.$t('donBox.nativeCampaign.ctrColumn'),
          tooltip: this.$t('donBox.nativeCampaign.ctrTooltip'),
          style: this.alignRight
        },
        {
          name: this.$t('donBox.nativeCampaign.clickPriceColumn'),
          tooltip: this.$t('donBox.nativeCampaign.clickPriceTooltip'),
          style: this.alignRight
        },
        { name: this.$t('donBox.nativeCampaign.sumPrice'), style: this.alignRight },
        { name: this.$t('donBox.nativeCampaign.budget'), style: this.alignRight },
        { name: this.$t('donBox.nativeCampaign.bonusFulfillmentColumn'), tooltip: this.$t('donBox.nativeCampaign.bonusFulfillmentTooltip') },
        {
          name: this.$t('donBox.nativeCampaign.rrk'), // rrk
          tooltip: this.$t('donBox.nativeCampaign.rrkTooltip'),
          style: this.alignRight
        },
        { name: this.$t('donBox.nativeCampaign.note') },
        { name: this.$t('donBox.nativeCampaign.salesman') }
      ]
    },
    nativeCampaigns () {
      return this.$store.getters['nativeCampaignManagement/list']
    },
    totalCount () {
      return this.$store.getters['nativeCampaignManagement/pagination'].totalCount
    },
    currentPage () {
      return this.$store.getters['nativeCampaignManagement/pagination'].currentPage
    },
    limit: {
      get () {
        return this.$store.getters['nativeCampaignManagement/pagination'].limit
      },
      set (limit) {
        this.$store.commit('nativeCampaignManagement/setLimit', limit)
        this.$store.commit('nativeCampaignManagement/setCurrentPage', 1)
        this.getNativeCampaigns()
      }
    },
    limitOptions () {
      const options = [10, 20, 50, 100]
      return options.map(limit => ({ id: limit, title: limit }))
    },
    pageCount () {
      return Math.ceil(this.totalCount / this.limit)
    },
    showInfoButton () {
      return !this.hideButtons
    }
  },
  methods: {
    showEditButton (item) {
      const hasPermission = this.$store.getters['user/hasPermission'](this.requiredPermissions.editButton)
      return hasPermission && item.status.toLowerCase() !== 'done' && !this.hideButtons
    },
    showDuplicateButton (item) {
      const hasPermission = this.$store.getters['user/hasPermission'](this.requiredPermissions.duplicateButton)
      return hasPermission && item.status.toLowerCase() === 'done' && !item.duplicate && !this.hideButtons
    },
    goToDuplicate (item) {
      this.$router.push(`/nativeCampaignManagement/${item.campaignId}/duplicate`)
    },
    getNativeCampaigns () {
      this.$store.dispatch('nativeCampaignManagement/fetch')
    },
    setCurrentPage (page) {
      this.$store.commit('nativeCampaignManagement/setCurrentPage', page)
    },
    setPageAndGetRecords (page) {
      this.setCurrentPage(page)
      this.getNativeCampaigns()
    }
  },
  created () {
    this.getNativeCampaigns()
  }
}
</script>
<style lang="scss">
  .native-campaign-table {
    font-size: rem(13px);
    width: rem(1750px);
    height: calc(100vh - 390px);
    min-height: rem(450px);
    background: none;
    &__thead,
    &__tr {
      grid-auto-flow: column;
      //                     status    agent      client     title      interval   paid     alternatives  days      visits    clicks    ctr       clickPrice sumPrice  budget    bonusFulfillment rrk
      grid-template-columns: rem(80px) rem(100px) rem(100px) rem(250px) rem(80px) rem(50px) rem(50px)     rem(50px) rem(80px) rem(90px) rem(50px) rem(50px)  rem(80px) rem(80px) rem(80px)        rem(50px) repeat(auto-fit, 80px);
    }
    .abstract-data-table__td {
      overflow-wrap: break-word;
    }
  }
</style>
<style  lang="scss" scoped>
.campaign-table-pagination {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  justify-content: space-between;
  margin-top: rem(10px);
  &__left-side {
    display: flex;
    align-items: center;
    gap: 1rem;
    @include font(400 16px "Roboto");
    color: #8A96AC;
  }
}
.number-column {
  text-align: right;
}
.centered-column {
  text-align: center;
}
.actions {
  display: flex;
  gap: rem(6px);
  margin-left: rem(-5px);
  margin-top: rem(8px);
}
.label-status {
  margin-left: rem(-5px);
  padding: rem(5px);
  border-radius: 0.4rem;
  text-transform: uppercase;
  color: #FFFFFF;
  overflow-wrap: normal;
  &--active {
    background-color: #26c6da;
  }
  &--inactive {
    background-color: #7460ee;
  }
  &--done {
    background-color: #fc4b6c;
  }
  &--paused {
    background-color: #41b883;
  }
}
</style>
