<template>
  <div class="native-performance-prediction-legend  d-flex flex-column justify-content-around">
    <div v-for="(item, index) in lineChartLegendData" :key="index" class="d-flex flex-column ">
      <span class="native-performance-prediction-legend__label font-weight-bold d-flex">
        {{ item.label }}
        <Tooltip :title="item.tooltip" :placement="'left'" class="ml-auto" color="lightblue"/>
      </span>
      <span class="native-performance-prediction-legend__value">{{ item.value }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Tooltip from '@/components/tooltip/Tooltip.vue'

export default {
  name: 'NativePerfPredictions',
  computed: {
    ...mapGetters({
      forecastOverall: 'nativePerfPredictions/forecastOverall'
    }),
    lineChartLegendData () {
      return [
        {
          label: this.$t('donBox.prediction.clicksForecast'),
          value: Number(this.forecastOverall.total.clicksForecast).toLocaleString('sk'),
          tooltip: this.$t('donBox.prediction.clicksForecastTooltip')
        },
        {
          label: this.$t('donBox.prediction.clicksReserved'),
          value: Number(this.forecastOverall.total.clicksReserved).toLocaleString('sk'),
          tooltip: this.$t('donBox.prediction.clicksReservedTooltip')
        },
        {
          label: this.$t('donBox.prediction.sold'),
          value: Number(this.forecastOverall.total.clicksReserved / this.forecastOverall.total.clicksForecast * 100 || 0).toFixed(2) + '%',
          tooltip: this.$t('donBox.prediction.soldTooltip')
        },
        {
          label: this.$t('donBox.prediction.clicks'),
          value: Number(this.forecastOverall.total.clicks).toLocaleString('sk'),
          tooltip: this.$t('donBox.prediction.clicksTooltip')
        }
      ]
    }
  },
  components: {
    Tooltip
  }
}
</script>

<style lang="scss" scoped>
.native-performance-prediction-legend {
  gap: rem(8px);

  &__label {
    font-size: rem(16px);
  }

  &__value {
    font-size: rem(14px)
  }
}
</style>
