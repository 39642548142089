<template>
  <button
    class="export-button"
    title="Export CSV"
    type="button"
    @click="exportToCsv"
  >
    {{ `Export CSV` }}
  </button>
</template>

<script>
export default {
  name: 'ButtonCsvExport',
  props: {
    data: {
      type: Array
    },
    filename: {
      type: String,
      default: 'export'
    },
    getCsvData: {
      type: Function,
      required: false
    }
  },
  methods: {
    async exportToCsv () {
      const csv = this.getCsvData ? await this.getCsvData() : this.$papa.unparse(this.data)
      const tempLink = document.createElement('a')
      tempLink.href = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csv)}`
      tempLink.setAttribute('download', this.filename + '.csv')
      tempLink.click()
    }
  }
}
</script>
<style scoped lang="scss">
.export-button {
  @include font(500 14px "Roboto");
  border-radius: rem(6px);
  border: 1px solid #D1DBE4;
  cursor: pointer;
  background-color: #6599FE;
  color: #FFFFFF;
  padding: rem(9px) rem(16px);
  &--disabled {
    background-color: #D1DBE4;
    cursor: default;
    &:hover {
      background-color: #D1DBE4;
    }
  }
}
</style>
