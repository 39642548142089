<template>
  <div class="card">
    <div class="card-body">
      <table class="table table-striped">
        <thead>
        <tr>
          <th></th>
          <th class="text-right" v-for="(item, index) in campaignReservedClicks.daily.dates" :key="index">
            {{ item }}
          </th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(row, campaignIndex) in campaigns" :key="`campaign-${campaignIndex}`">
          <td :class="{ 'text-green' : row.status === 4 }">{{ row.title }}</td>
          <!--          TODO ? row status === 4  -->
          <!--          <td class="text-right" :class="{ 'text-green' : row.status === 'ACTIVE' }">{{ row.originalEstimate }}</td>-->
          <td v-for="(date, index) in campaignReservedClicks.daily.dates" :key="`item-${index}-${campaignIndex}`"
              class="text-right" :class="{ 'text-green' : row.status === 'ACTIVE' }">
            {{ getClicksForCampaignAndDate(row.campaignId, date) }}
          </td>
        </tr>

        <tr>
          <td class="font-weight-bold">{{ $t('don.npa.total_daily_campaign_performance') }}</td>
          <td v-for="(dailyPerformance, index ) in dailyTotalSorted" :key="`clicks-${index}`"
              class="text-right" :class="highlightPerformance(dailyPerformance)"
          >
            {{ dailyPerformance.clicks }}
          </td>
        </tr>

        <tr>
          <td class="font-weight-bold">{{ $t('don.npa.predicted_estimate_by_campaigns') }}</td>
          <td v-for="(predictedEstimate, index) in dailyTotalSorted" :key="`clicks-reserved-${index}`"
              class="text-right font-weight-bold">
            {{ predictedEstimate.clicksReserved }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { sortDate } from '@/helpers/date'

export default {
  name: 'NativePerfPredictionTable',
  data () {
    return {}
  },
  components: {},
  computed: {
    ...mapGetters({
      campaignReservedClicks: 'nativePerfPredictions/campaignReservedClicks',
      campaigns: 'nativeCampaignManagement/list',
      filter: 'nativeCampaignManagement/filter'
    }),
    dailyTotalSorted () {
      return [...this.campaignReservedClicks.dailyTotal].sort(sortDate)
    }
  },
  methods: {
    sortDate,
    getClicksForCampaignAndDate (campaignId, date) {
      const entry = this.campaignReservedClicks.daily.data.find(
        item => item.campaignId === campaignId && item.date === date
      )
      return entry ? entry.clicks : 0
    },
    fetchCampaignReservedClicks (payload) {
      this.$store.dispatch('nativePerfPredictions/fetchCampaignReservedClicks', payload)
    },
    highlightPerformance ({ clicks, clicksReserved }) {
      if (clicks > clicksReserved) {
        const ratio = clicks / clicksReserved

        if (ratio > 1.2) {
          return 'text-danger'
        } else if (ratio <= 1.2 && ratio > 1) {
          return 'text-warning'
        }
      }
    }
  },
  created () {
    this.fetchCampaignReservedClicks({ campaignIds: this.campaigns.map(campaign => campaign.campaignId) })
  },
  watch: {
    campaigns (newValue) {
      this.fetchCampaignReservedClicks({ campaignIds: newValue.map(campaign => campaign.campaignId), dateFrom: this.filter.from })
    },
    deep: true
  }
}
</script>

<style lang="scss">
table {
  th {
    &:nth-child(1) {
      width: 26%;
    }

    &:nth-child(2) {
      width: 10%;
    }

    &:nth-child(n+3) {
      width: 8%;
    }
  }
}

.text-green {
  color: #41b883;
}
</style>
