import { Line, mixins } from 'vue-chartjs'
import { formatNumber } from '@/filters'

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    maxLabels: {
      type: Number,
      default: 0
    },
    displayLegend: {
      type: Boolean,
      default: true
    },
    legendPosition: {
      type: String,
      default: 'top'
    },
    stacked: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    tooltipLabel: {
      type: Function,
      default: (tooltipItem, data) => [
        data.datasets[tooltipItem.datasetIndex].label,
        formatNumber(tooltipItem.yLabel)
      ].filter((i) => i).join(': ')
    },
    hideZeroValuesTooltip: {
      type: Boolean,
      default: false
    },
    xLabelName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      options: {
        title: {
          display: this.title !== '',
          text: this.title,
          fontSize: 20,
          padding: 5,
          lineHeight: 1
        },
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 0,
            bottom: -10
          }
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: this.tooltipLabel
          },
          filter: (a) => {
            if (this.hideZeroValuesTooltip) {
              return a.yLabel > 0
            }
            return true
          },
          itemSort: (a, b) => b.yLabel - a.yLabel
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 5,
            hoverRadius: 5
          }
        },
        legend: {
          position: this.legendPosition,
          display: this.displayLegend
        },
        scales: {
          yAxes: [{
            stacked: this.stacked,
            ticks: {
              callback (label) {
                return formatNumber(label)
              }
            }
          }],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.xLabelName
              },
              ticks: {
                display: true,
                autoSkip: true,
                maxTicksLimit: this.maxLabels
              }
            }
          ]
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
